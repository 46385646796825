import React from "react";
import { toast } from "react-toastify";

import { IconButton } from "@spesill/components/molecules";

interface GroundingToggleProps {
  isEnabled: boolean; // 状態をpropsとして追加
  onToggle: (enabled: boolean) => void;
}

const GroundingToggle = ({
  isEnabled, // isEnabledをpropsとして受け取る
  onToggle,
}: GroundingToggleProps) => {
  const handleClick = () => {
    const newState = !isEnabled;
    onToggle(newState);

    toast.info(
      newState ? "web検索がオンになりました" : "web検索がオフになりました",
    );
  };

  return (
    <div className="relative">
      <IconButton
        icon={{
          icon: "faGlobe",
          size: "1rem",
          color: isEnabled ? "text-cyan-500" : "text-blueGray-500",
        }}
        onClick={handleClick}
        className="p-2 hover:bg-blueGray-100 rounded-full transition-colors"
        aria-label="web検索"
        tooltip={{
          text: "web検索",
          direction: "top",
          className: "text-xs",
        }}
      />
    </div>
  );
};

export default GroundingToggle;
