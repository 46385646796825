import type { AspidaClient } from 'aspida';
import type { Methods as Methods_1fxhukr } from './ai_chat';
import type { Methods as Methods_k693l1 } from './evaluate_spec';
import type { Methods as Methods_1793qsq } from './extract_flowchart_format';
import type { Methods as Methods_1r2dmfd } from './generate_excel_text';
import type { Methods as Methods_1hu330k } from './generate_punch_image';
import type { Methods as Methods_14gi3ae } from './generate_punch_image_in_detail';
import type { Methods as Methods_wzur75 } from './generate_spec';
import type { Methods as Methods_1novdwl } from './generate_spec_headings';
import type { Methods as Methods_k9js1f } from './generate_text_from_sheets';
import type { Methods as Methods_1kjfsnf } from './generate_vector_indices';
import type { Methods as Methods_17cgnov } from './get_keywords';
import type { Methods as Methods_1os9ziv } from './refine_text';
import type { Methods as Methods_1jnss3f } from './register_feedback';
import type { Methods as Methods_1oicy6r } from './search_documents';
import type { Methods as Methods_1e247zu } from './speechToText';
import type { Methods as Methods_jm96ga } from './speech_to_text';
import type { Methods as Methods_jte2io } from './suggest_prompts';
import type { Methods as Methods_wf7tun } from './video_upload';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '');
  const PATH0 = '/ai_chat';
  const PATH1 = '/evaluate_spec';
  const PATH2 = '/extract_flowchart_format';
  const PATH3 = '/generate_excel_text';
  const PATH4 = '/generate_punch_image';
  const PATH5 = '/generate_punch_image_in_detail';
  const PATH6 = '/generate_spec';
  const PATH7 = '/generate_spec_headings';
  const PATH8 = '/generate_text_from_sheets';
  const PATH9 = '/generate_vector_indices';
  const PATH10 = '/get_keywords';
  const PATH11 = '/refine_text';
  const PATH12 = '/register_feedback';
  const PATH13 = '/search_documents';
  const PATH14 = '/speechToText';
  const PATH15 = '/speech_to_text';
  const PATH16 = '/suggest_prompts';
  const PATH17 = '/video_upload';
  const POST = 'POST';

  return {
    ai_chat: {
      post: (option: { body: Methods_1fxhukr['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1fxhukr['post']['resBody']>(prefix, PATH0, POST, option).json(),
      $post: (option: { body: Methods_1fxhukr['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1fxhukr['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH0}`,
    },
    evaluate_spec: {
      post: (option: { body: Methods_k693l1['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_k693l1['post']['resBody']>(prefix, PATH1, POST, option).json(),
      $post: (option: { body: Methods_k693l1['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_k693l1['post']['resBody']>(prefix, PATH1, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH1}`,
    },
    extract_flowchart_format: {
      post: (option: { body: Methods_1793qsq['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1793qsq['post']['resBody']>(prefix, PATH2, POST, option).json(),
      $post: (option: { body: Methods_1793qsq['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1793qsq['post']['resBody']>(prefix, PATH2, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH2}`,
    },
    generate_excel_text: {
      post: (option: { body: Methods_1r2dmfd['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1r2dmfd['post']['resBody']>(prefix, PATH3, POST, option).json(),
      $post: (option: { body: Methods_1r2dmfd['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1r2dmfd['post']['resBody']>(prefix, PATH3, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH3}`,
    },
    generate_punch_image: {
      post: (option: { body: Methods_1hu330k['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1hu330k['post']['resBody']>(prefix, PATH4, POST, option).json(),
      $post: (option: { body: Methods_1hu330k['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1hu330k['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH4}`,
    },
    generate_punch_image_in_detail: {
      post: (option: { body: Methods_14gi3ae['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_14gi3ae['post']['resBody']>(prefix, PATH5, POST, option).json(),
      $post: (option: { body: Methods_14gi3ae['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_14gi3ae['post']['resBody']>(prefix, PATH5, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH5}`,
    },
    generate_spec: {
      post: (option: { body: Methods_wzur75['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_wzur75['post']['resBody']>(prefix, PATH6, POST, option).json(),
      $post: (option: { body: Methods_wzur75['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_wzur75['post']['resBody']>(prefix, PATH6, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH6}`,
    },
    generate_spec_headings: {
      post: (option: { body: Methods_1novdwl['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1novdwl['post']['resBody']>(prefix, PATH7, POST, option).json(),
      $post: (option: { body: Methods_1novdwl['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1novdwl['post']['resBody']>(prefix, PATH7, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH7}`,
    },
    generate_text_from_sheets: {
      post: (option: { body: Methods_k9js1f['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_k9js1f['post']['resBody']>(prefix, PATH8, POST, option).json(),
      $post: (option: { body: Methods_k9js1f['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_k9js1f['post']['resBody']>(prefix, PATH8, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH8}`,
    },
    generate_vector_indices: {
      post: (option: { body: Methods_1kjfsnf['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1kjfsnf['post']['resBody']>(prefix, PATH9, POST, option).json(),
      $post: (option: { body: Methods_1kjfsnf['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1kjfsnf['post']['resBody']>(prefix, PATH9, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH9}`,
    },
    get_keywords: {
      post: (option: { body: Methods_17cgnov['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_17cgnov['post']['resBody']>(prefix, PATH10, POST, option).json(),
      $post: (option: { body: Methods_17cgnov['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_17cgnov['post']['resBody']>(prefix, PATH10, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH10}`,
    },
    refine_text: {
      post: (option: { body: Methods_1os9ziv['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1os9ziv['post']['resBody']>(prefix, PATH11, POST, option).json(),
      $post: (option: { body: Methods_1os9ziv['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1os9ziv['post']['resBody']>(prefix, PATH11, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH11}`,
    },
    register_feedback: {
      post: (option: { body: Methods_1jnss3f['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1jnss3f['post']['resBody']>(prefix, PATH12, POST, option).json(),
      $post: (option: { body: Methods_1jnss3f['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1jnss3f['post']['resBody']>(prefix, PATH12, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH12}`,
    },
    search_documents: {
      post: (option: { body: Methods_1oicy6r['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1oicy6r['post']['resBody']>(prefix, PATH13, POST, option).json(),
      $post: (option: { body: Methods_1oicy6r['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1oicy6r['post']['resBody']>(prefix, PATH13, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH13}`,
    },
    speechToText: {
      post: (option: { body: Methods_1e247zu['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1e247zu['post']['resBody']>(prefix, PATH14, POST, option).json(),
      $post: (option: { body: Methods_1e247zu['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_1e247zu['post']['resBody']>(prefix, PATH14, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH14}`,
    },
    speech_to_text: {
      post: (option: { body: Methods_jm96ga['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_jm96ga['post']['resBody']>(prefix, PATH15, POST, option).json(),
      $post: (option: { body: Methods_jm96ga['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_jm96ga['post']['resBody']>(prefix, PATH15, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH15}`,
    },
    suggest_prompts: {
      post: (option: { body: Methods_jte2io['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_jte2io['post']['resBody']>(prefix, PATH16, POST, option).json(),
      $post: (option: { body: Methods_jte2io['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_jte2io['post']['resBody']>(prefix, PATH16, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH16}`,
    },
    video_upload: {
      post: (option: { body: Methods_wf7tun['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_wf7tun['post']['resBody']>(prefix, PATH17, POST, option).json(),
      $post: (option: { body: Methods_wf7tun['post']['reqBody'], config?: T | undefined }) =>
        fetch<Methods_wf7tun['post']['resBody']>(prefix, PATH17, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH17}`,
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
