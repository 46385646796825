import React, { FC } from "react";

import { SubjectType } from "@spesill/customFeatures/subjectSelection/hooks/subjectData";

type Props = {
  isEnabled: boolean;
  selectedSubject: SubjectType | "";
  onSubjectChange: (value: string) => void;
  subjectOptions: Array<{ value: string; label: string }>;
};

export const SubjectSelectionFeature: FC<Props> = ({
  isEnabled,
  selectedSubject,
  onSubjectChange,
  subjectOptions,
}) => {
  if (!isEnabled) return null;

  return (
    <div className="flex flex-col gap-y-2 mt-2">
      <div className="flex flex-wrap gap-x-4 gap-y-2">
        {subjectOptions.map((option) => (
          <label
            key={option.value}
            className="flex items-center cursor-pointer"
          >
            <input
              type="radio"
              name="subjectSelection"
              value={option.value}
              checked={selectedSubject === option.value}
              onChange={() => onSubjectChange(option.value)}
              className="form-radio mr-1 text-primary-400 focus:ring-primary-400"
            />
            <span className="ml-1">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};
