import React, { FC, useState } from "react";

import { useArray } from "@spesill/hooks";
import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequestBase";
import { Document } from "@spesill/models";
import { CorrectionRule } from "@spesill/models/api/evaluate_spec";

import { AiSentenceCheckMenu } from "./Menus/AiSentenceCheckMenu";
import { RightFloatIcons } from "./RightFloatIcons";
import { RightFloatMenu } from "./RightFloatMenu";

export const MenuComponentList = {
  "": undefined,
  AI文章チェック: {
    component: ({
      setCurrentMenu,
      document,
      review,
      suggestions,
      corrections,
      rules,
      requestEvaluate,
      isEvaluating,
      onReflectCorrection,
      onCancelCorrection,
      onFocusCorrection,
      onUpdateCorrection,
      resetEvaluation,
    }: {
      document: Document;
      setCurrentMenu: (menu: "") => void;
      review?: string;
      suggestions?: string[];
      corrections?: CorrectionType[];
      rules?: CorrectionRule[];
      requestEvaluate?: (databaseId: string) => void;
      isEvaluating?: boolean;
      onReflectCorrection?: (correction: CorrectionType) => void;
      onCancelCorrection?: (correction: CorrectionType) => void;
      onFocusCorrection?: (correction: CorrectionType) => void;
      onUpdateCorrection?: (correction: CorrectionType) => void;
      resetEvaluation?: () => void;
    }) => (
      <AiSentenceCheckMenu
        document={document}
        setCurrentMenu={setCurrentMenu}
        review={review}
        suggestions={suggestions}
        corrections={corrections}
        requestEvaluate={requestEvaluate}
        isEvaluating={isEvaluating}
        onCancelCorrection={onCancelCorrection}
        onReflectCorrection={onReflectCorrection}
        onFocusCorrection={onFocusCorrection}
        onUpdateCorrection={onUpdateCorrection}
        resetEvaluation={resetEvaluation}
        rules={rules}
      />
    ),
    icon: "biListCheck",
  },
} as const;
export type MenuNameListType = keyof typeof MenuComponentList;
export const MenuList = Object.keys(MenuComponentList) as MenuNameListType[];

type PropsType = {
  document: Document;
  headingTitles: string[];
  setRequestHeadingTitles: (headingTitles: string[]) => void;
  review?: string;
  suggestions?: string[];
  corrections?: CorrectionType[];
  rules?: CorrectionRule[];
  requestEvaluate?: (databaseId: string, proofreaderPrompt?: string) => void;
  isEvaluating?: boolean;
  onReflectCorrection?: (correction: CorrectionType) => void;
  onCancelCorrection?: (correction: CorrectionType) => void;
  onFocusCorrection?: (correction: CorrectionType) => void;
  onUpdateCorrection?: (correction: CorrectionType) => void;
  resetEvaluation?: () => void;
  className?: string;
};

export const RightFloatSidebar: FC<PropsType> = ({
  setRequestHeadingTitles,
  headingTitles,
  document,
  review,
  suggestions,
  corrections,
  rules,
  requestEvaluate,
  isEvaluating = false,
  onReflectCorrection,
  onCancelCorrection,
  onFocusCorrection,
  onUpdateCorrection,
  resetEvaluation,
  className,
}) => {
  const [currentMenu, setCurrentMenu] = useState<MenuNameListType>("");
  const {
    items: selectedHeadingTitles,
    pushItem: pushSelectedHeadingTitle,
    findAndRemove: findAndRemoveSelectedHeadingTitle,
  } = useArray<string>([]);

  return (
    <div className={`relative ${className}`}>
      {currentMenu ? (
        <RightFloatMenu
          currentMenu={currentMenu}
          setCurrentMenu={setCurrentMenu}
          setRequestHeadingTitles={setRequestHeadingTitles}
          headingTitles={headingTitles}
          document={document}
          selectedHeadingTitles={selectedHeadingTitles}
          pushSelectedHeadingTitle={pushSelectedHeadingTitle}
          findAndRemoveSelectedHeadingTitle={findAndRemoveSelectedHeadingTitle}
          review={review}
          suggestions={suggestions}
          corrections={corrections}
          rules={rules}
          requestEvaluate={requestEvaluate}
          isEvaluating={isEvaluating}
          onReflectCorrection={onReflectCorrection}
          onCancelCorrection={onCancelCorrection}
          onFocusCorrection={onFocusCorrection}
          onUpdateCorrection={onUpdateCorrection}
          resetEvaluation={resetEvaluation}
        />
      ) : (
        <RightFloatIcons
          setCurrentMenu={setCurrentMenu}
          currentMenu={currentMenu}
        />
      )}
    </div>
  );
};
