export type SubjectType =
  | "japanese"
  | "math"
  | "english"
  | "science"
  | "socialStudies";

// 教科選択用のオプション
export const subjectOptions = [
  { value: "japanese", label: "国語" },
  { value: "math", label: "数学" },
  { value: "english", label: "英語" },
  { value: "science", label: "理科" },
  { value: "socialStudies", label: "社会" },
];

export const guidelineCommon: string = `

-   **誤字脱字**: 単純なタイプミスや変換ミスがないか。
-   **番号・記号の整合性**:
    -   問題番号（大問、小問）、ページ番号、図表番号、注釈番号などに抜け、重複、順序間違いがないか。
    -   選択肢の記号（ア、イ、ウ、エなど）に抜け、重複、順序間違いがないか。
    -   各種番号や記号が欠落していないか。
-   **表記・スタイルの統一性**:
    -   教材全体で、全角/半角、数字（アラビア数字、漢数字）、括弧の種類、句読点（。、 、，．）、見出しレベルなどの表記スタイルが統一されているか。
    -   大問番号、小問番号、選択肢記号などの形式が統一されているか（例：算用数字か漢数字か、括弧の有無など）。
-   **参照の正確性**:
    -   本文中で言及されている図、表、ページ番号、問題番号などが正しく対応しているか。
    -   解答・解説中で参照されている問題番号や箇所が正しいか。
-   **選択問題の指示**: 選択問題がある場合、解答すべき問題の指示が明確か。

### 基本的な注意点の具体例 (全教科共通)

#### 1. 抜け・欠番 (Omission)

-   **大問番号の飛び**: （誤）大問構成が 1 → 2 → 4 → 5 となっている → （正）大問 3 が欠番。追加するか、以降の番号を繰り上げる必要がある。
-   **小問番号の飛び**: （誤）大問2の設問が 問1 → 問3 となっている → （正）問2 が欠番。追加するか、問3 を 問2 に修正する必要がある。（例：赤字例 問題.pdf 大問1 問3の次が問5になっている）
-   **図・表番号の飛び**: （誤）本文中に登場する図が 図1 → 図3 となっている → （正）図2 が欠落しているか、図3 は 図2 の誤記。
-   **選択肢記号の飛び**: （誤）選択肢が ア → イ → エ となっている → （正）記号 ウ が抜けている。追加するか、エ を ウ に修正。
-   **手順・箇条書き番号の飛び**: （誤）実験手順が ① → ② → ④ となっている → （正）手順 ③ が抜けている。

#### 2. 重複 (Duplication)

-   **小問番号の重複（同一大問内）**: （誤）大問4の末尾に 問5 が2つ存在する → （正）後の 問5 は 問6 の誤り。（例：赤字例 問題.pdf 大問4）
-   **図・表番号の重複**: （誤）異なる内容の図に同じ「図1」という番号が付いている → （正）後の図の番号を「図2」などに修正。
-   **選択肢記号の重複（同一問題内）**: （誤）選択肢が ア → イ → ア → ウ となっている → （正）重複している ア を別の記号（例：エ）に修正するか、選択肢内容を確認。
-   **注釈番号の重複**: （誤）本文中に注釈を示す「*1」が複数箇所に存在する → （正）後の注釈番号を「*2」などに修正。

#### 3. 連続性の欠如・順序間違い (Sequence Error)

-   **ページ番号の誤り・不連続**:
    -   （誤）ページ番号が「-2-」→「-4-」となっている → （正）ページ「-3-」が欠落しているか、ノンブル（ページ番号）の誤植。
    -   （誤）ページ番号「3」が「15」のように大きく飛んでいる → （正）正しいページ番号「3」に修正（単純な誤植）。（例：赤字例 問題.pdf p.3に「3 ページ番号」の赤字）
-   **選択肢記号の順序**: （誤）選択肢が ア → ウ → イ → エ の順 → （正）ア → イ → ウ → エ の正しい順序に修正。
-   **図・表と本文の参照順序**: （誤）本文中で「図2に示すように…」と言及した後に「図1は…」という説明が登場する → （正）図と本文の登場順序を一致させる（図の番号を振り直すか、本文の構成を見直す）。

#### 4. 記号・番号自体の欠落 (Missing Element)

-   **図・表番号の欠落**: （誤）図版自体は存在するが、「図○」という番号が付与されていない → （正）図に対応する番号（例：「図2」）を追加。（例：赤字例 問題.pdf 問4の図）
-   **大問・小問番号の欠落**: （誤）設問文の前に番号が付与されていない → （正）大問・小問に応じた番号（例：「３」や「問1」）を追加。
-   **選択肢記号の欠落**: （誤）選択肢の各項目文の先頭に記号が付与されていない → （正）「ア、イ、ウ、エ」などの選択肢記号を追加。

#### 5. 表記揺れ・形式不統一 (Inconsistent Formatting)

-   **大問番号の表記混在**: （誤）大問番号が「1」「二」「Ⅲ」のように形式が統一されていない → （正）指定された形式（例：アラビア数字）に統一。
-   **小問番号の表記混在**: （誤）小問番号が「問1」「(2)」「③」のように形式が統一されていない → （正）指定された形式（例：「問1、問2...」や「(1)、(2)...」）に統一。
-   **選択肢記号の表記混在**: （誤）選択肢記号が「ア」「(イ)」「C」「(d)」のように形式が統一されていない → （正）指定された形式（例：「ア、イ、ウ、エ」や「A, B, C, D」）に統一。
-   **数字の全角/半角混在**: （誤）問題番号やページ番号で「３」（全角）と「4」（半角）が混在している → （正）指定された形式（通常は半角アラビア数字）に統一。

#### 6. 参照ミス (Reference Error)

-   **本文中の図・表番号参照ミス**: （誤）本文中に「図5に示すように…」とあるが存在しない → （正）本文中の参照番号を存在する図番号（例：「図4」）に修正するか、図5を追加。
-   **解答・解説中の問題番号参照ミス**: （誤）解説文で「問3の解答参照」とあるが、実際には問2の内容について言及している → （正）参照している問題番号を正しいものに修正。

#### 7. その他（選択問題など）

-   **選択問題の指示不備**: （誤）選択問題AとBがあるが、どちらを解答すべきかの指示が冒頭や設問内にない → （正）「選択問題A・Bどちらか一方だけの問題を解きなさい」等の明確な指示を追加。（例：赤字例 問題.pdf 大問7）
-   **選択問題の番号付け**: （指示内容）選択問題AとBで、大問番号や小問番号が重複しないか、または区別されているか（例：7-A 問1, 7-B 問1）を確認。
`;

// 数学
const guidelineMath: string = `
### 数学 (Mathematics)

**校正者としての注意点**

-   **数式、記号、単位の絶対的な正確性**を確認してください。わずかな誤りも許容されません。
-   **数学用語の定義**に基づいた厳密な使用がされているか、徹底的に確認してください。
-   **図やグラフ**は、それ自体が正確であるだけでなく、**本文や数式との完全な整合性**が取れているか、細部まで確認してください。
-   **問題文の意図の明確さ**と、**解答・解説の論理的な正しさ、網羅性**を確認してください。中学生の視点での分かりやすさも考慮してください。
-   **学習指導要領**を意識し、範囲逸脱や学年配当に不適切な内容、表現がないか注意してください。
-   教材全体の**表記（全角/半角、漢字/ひらがな等）やスタイルの統一性**を維持するように確認してください。
-   **誤字脱字、番号（問題番号、ページ番号等）の整合性**といった、教材としての基本的な品質を担保するチェックも怠らないでください。

**具体例**

#### 1. 問題文・設問の修正

-   **数値・記号・単位の誤り**:
    -   （誤）比例式 16:x = 12:x → （正）16:12 = 12:x （数値を修正）
    -   （誤）比の表記 BF:FC-3:2 → （正）BF:FC=3:2 （記号を修正）
-   **数式の誤り**:
    -   （誤）関数の式 y = 1/2 x^3 → （正）y = 1/2 x^2 （指数の誤植修正）
    -   （誤）(8xy-4x) ÷ 4x → （正）(8x^2y - 4x^2) ÷ 4x （指数や変数の抜けを修正 ※元の意図により修正内容は変動）
-   **問題設定の矛盾・不備**:
    -   （指示内容）設問文と図の設定が矛盾している箇所（例：角度や長さ）を修正

#### 2. 図・グラフの修正

-   **図形の正確性**:
    -   （指示内容）三角柱の図で、指定された辺の長さや高さの比率が不正確なため修正
    -   （指示内容）平行四辺形の図で、指定された角度や対角線の交点の位置が不正確なため修正
    -   （指示内容）円の図で、指定された角度や線分の関係が正確に描かれていない、または設定としてありえない図形になっているため修正
-   **図と問題文・解答の整合性**:
    -   （誤）グラフの縦軸ラベル「学校」 → （正）問題文に合わせて「駅」に修正
    -   （誤）証明問題で指定されている三角形（△COF）と解答に書かれている三角形（△COB）が異なる → （正）解答の三角形の記号を修正
-   **グラフ要素の正確性**:
    -   （指示内容）グラフの軸の目盛りや数値、プロットされた点の位置のズレを修正
    -   （指示内容）グラフの線が指定された条件（例：毎分100mの速さ）と合っていないため修正

#### 3. 解答・解説の修正

-   **解答の形式（単位など）**:
    -   （誤）解答欄に単位 cm がない → （正）単位 cm を追記
    -   （誤）解答欄に単位 度 がない → （正）単位 度 を追記
    -   （誤）解答欄に単位 個 がない → （正）単位 個 を追記
-   **証明の不備**:
    -   （指示内容）証明問題の解答に、根拠となる事柄（定理や定義など）を明記するよう指示
-   **記号・文字の誤り**:
    -   （誤）解答中の文字 y （計算過程や最終的な式など） → （正）正しい文字 x に修正
`;

// 国語
const guidelineJapanese: string = `
### 国語 (Japanese)

**校正者としての注意点**

-   **漢字・仮名遣い・送り仮名の正確性**を最優先で確認してください。特に公的な規範（常用漢字表、現代仮名遣い、送り仮名の付け方）への準拠を徹底してください。
-   **文法的な誤り**、特に助詞、接続表現、敬語の用法、文のねじれに注意してください。
-   **問題文と本文、および解答・解説との論理的な整合性**を厳密に確認してください。設問は本文から解答可能か、解答は客観的に正しいか。
-   **中学生の読解力や知識レベル**を常に念頭に置き、語彙や表現が適切かどうか判断してください。
-   **古典（古文・漢文）**に関する記述（本文、訳、解説）は、専門的な観点からも正確性を確認してください。
-   **学習指導要領**との整合性を意識し、範囲逸脱やレベルの不一致がないか注意してください。
-   校正の際には対象が問題文であるか本文であるかを常に意識しその上で校正を行ってください。
-   **誤字脱字、番号（問題番号、ページ番号等）の整合性**といった、教材としての基本的な品質を担保するチェックも怠らないでください。
-   教材全体の**表記（全角/半角、漢字/ひらがな、縦書き/横書きの統一など）やスタイルの統一性**を維持するように確認してください。

**具体例**
*(注: 提供された具体例\`japanese_examples\`は社会科の内容と酷似しており、国語科の例として不適切である可能性が高いため、国語科の注意点に基づいた一般的な例を以下に記載します。)*

-   **漢字・仮名遣いの誤り**:
    -   （誤）俳句を読む → （正）俳句を詠む
    -   （誤）郎読 → （正）朗読
-   **送り仮名の誤り** (内閣告示「送り仮名の付け方」準拠):
    -   （誤）行なう → （正）行う
    -   （誤）断わる → （正）断る
    -   （誤）表わす → （正）表す
-   **文法的な誤り**:
    -   （誤）食べれる → （正）食べられる （ら抜き言葉の修正）
    -   （誤）先生が申された → （正）先生がおっしゃった （敬語の誤用）
    -   （誤）理由としては、〜だからです。 → （正）理由は、〜だからです。／〜だからです。（文のねじれ・冗長表現の修正）
-   **問題と本文・解答の不整合**:
    -   （指示内容）設問の解答根拠が本文中に見当たらない箇所を指摘。
    -   （指示内容）記述問題の解答例が、設問の要求（字数制限、含めるべき要素など）を満たしていない箇所を指摘。
    -   （指示内容）選択肢問題で、正解以外の選択肢が明らかに不適切すぎる、または正解と判断できるものが複数存在する可能性がないか確認。
-   **古典の記述**:
    -   （指示内容）古文の現代語訳が原文の解釈として不自然、または誤っている箇所を修正。
    -   （指示内容）漢文の返り点、送り仮名、書き下し文に誤りがないか確認。
    -   （指示内容）歴史的仮名遣いと現代仮名遣いの使い分けが適切か確認。
`;

// 理科
const guidelineScience: string = `
### 理科 (Science)

**校正者としての注意点**

-   **科学用語、単位、化学式、各種記号**など、専門的な記述の**絶対的な正確性**を最優先で確認してください。誤りは許容されません。
-   **図、グラフ、表**は、それ自体が科学的に正確であるだけでなく、**本文や関連情報との完全な整合性**が取れているか、細部まで確認してください。
-   **実験・観察**については、手順の**安全性と再現性**、および結果と考察の**科学的な妥当性**を厳密に確認してください。
-   **事実、法則、原理、概念の説明**において、**正確性と論理的な一貫性**を徹底的に確認してください。
-   **中学生の知識レベルや思考力**を常に念頭に置き、説明の**分かりやすさと科学的な正確性のバランス**が取れているか判断してください。
-   **学習指導要領**で定められた各分野（物理、化学、生物、地学）の**内容、範囲、深さ**に準拠しているか、逸脱がないか注意してください。
-   **誤字脱字、番号（問題番号、ページ番号等）の整合性**といった、教材としての基本的な品質を担保するチェックも怠らないでください。
-   教材全体の**表記（全角/半角、単位表記、化学式の上下付き文字など）やスタイルの統一性**を維持するように確認してください。

**具体例**

#### 1. 問題文・設問・表の修正

-   **誤字・脱字修正**:
    -   （誤）表中の「ホニュウ類」のなかまのふやし方「船生」 → （正）「胎生」
    -   （誤）表中の「両性類」 → （正）「両生類」
    -   （誤）問題文中の「動物の内蔵」 → （正）「動物の内臓」
-   **表現の正確性・明確性（助詞など）**:
    -   （指示内容）実験手順③の助詞「てにをは」の誤りを修正
-   **科学的内容の誤り・不備**:
    -   （指示内容）表冒頭の説明文の内容を最新の知見に合わせて修正
    -   （誤）実験④「電極Xから気体Eが発生し、電極Yに赤かっ色の物質が付着した」 → （正）電気分解の原理に基づき、発生する物質と電極の関係を正しく記述（例：「電極Yから気体Eが発生し、電極Xに赤かっ色の物質が付着した」など）
-   **用語の欠落**:
    -   （誤）問2選択肢エ「ナリウムイオン」→（正）「ナトリウムイオン」

#### 2. 図・グラフの修正

-   **図の内容・正確性**:
    -   （誤）図2「葉の断面」「茎の断面」の模式図 → （正）実際の構造に基づいた、より正確な模式図に修正
    -   （誤）図1（南の空の図）の方位表記「南東」 → （正）文脈に合わせて「東」に修正（または図自体を修正）
    -   （誤）実験装置の図と問題文（BTB溶液を加えたうすい塩酸に水酸化ナトリウム水溶液を加える）が一致しない → （正）図または問題文を修正し整合性を取る
-   **単位・ラベルの誤り**:
    -   （誤）図1（電圧-電流グラフ）の横軸ラベル「電圧 [A]」 → （正）「電圧 [V]」
`;

// 社会
const guidelineSocial: string = `
### 社会 (Social Studies)

**校正者としての注意点**

-   **事実関係の正確性**を最優先で確認してください。地名、人名、年代、出来事、制度、統計データなど、あらゆる固有名詞や数値に細心の注意を払ってください。
-   **地図、グラフ、年表、図版などの資料**と、**本文の記述との完全な整合性**を厳密に確認してください。矛盾や食い違いがないか。
-   特に**歴史分野、公民分野**においては、記述が特定の思想や価値観に偏っていないか、**客観性・中立性**の観点からも確認してください。
-   **公民分野**における**日本国憲法、法律、政治・経済制度**に関する記述は、法的な正確性を担保するように特に注意深く確認してください。
-   **地理・歴史・公民の各分野**における**専門用語**は、その定義に基づいて正確に、かつ文脈に即して適切に使用されているか確認してください。
-   **中学生の知識レベルや理解力**を常に念頭に置き、記述が**分かりやすい**か、同時に**科学的・社会的な正確性**が損なわれていないか、バランスを確認してください。
-   **学習指導要領**で定められた内容、範囲、深さに準拠しているか、逸脱がないか注意してください。
-   **情報の鮮度**: 特に地理分野の統計データや公民分野の国際情勢、制度に関する記述が古すぎないか、現在の状況を意識して確認してください。

**具体例**

#### 1. 誤字・脱字、用語の修正・統一

-   **誤字修正**:
    -   （誤）矢茜洋 → （正）大西洋
-   **用語の正確化**:
    -   （誤）尖 諸島 → （正）尖閣諸島
-   **用語の補足（別名・略称）**:
    -   （修正前）季節風 → （修正後）季節風 [モンスーン]
    -   （修正前）アフリカ連合 → （修正後）アフリカ連合[AU]
    -   （修正前）非政府組織 → （修正後）非政府組織[NGO]
    -   （修正前）情報通信技術 → （修正後）情報通信技術[ICT]
    -   （修正前）石油輸出国機構 → （修正後）石油輸出国機構[OPEC]
-   **表現の明確化**:
    -   （修正前）特定の農産物を適正価格で取引する取り組み → （修正後）フェアトレード

#### 2. 図版・資料の修正・更新

-   **地図情報の更新**:
    -   （指示内容）気候分布図を最新の資料（例：ディルケアトラス等）に基づいて修正
    -   （指示内容）宗教分布図を最新の資料に基づいて修正
    -   （指示内容）鉱産資源分布図を最新の資料に基づいて修正
-   **統計データの更新**:
    -   （指示内容）グラフ・表内の統計データの出典年度を最新版に更新
-   **図版内容の改善**:
    -   （指示内容）写真「仏像」をより分かりやすいものに差し替え
    -   （指示内容）図「北アメリカの気温・降水量分布」を新規作成
`;

// 英語
const guidelineEnglish: string = `
### 英語 (English)

**校正者としての注意点**

-   **文法・語彙・スペリングの絶対的な正確性**を最優先で確認してください。一つの誤りが学習者の誤解や混乱を招く可能性があります。
-   **解答（Answer Key）が完全に正しい**か、細心の注意を払って確認してください。
-   **本文、設問、選択肢、指示文、解答、解説の間**で、内容や英語表現に**矛盾や不整合がない**か、徹底的に確認してください。
-   常に**対象となる中学生のレベル（学年、既習事項）**を念頭に置き、難易度、語彙、文法項目が適切かどうか判断してください。
-   **学習指導要領**で定められた範囲や目標から逸脱していないか、意識して確認してください。
-   **問題番号、選択肢記号、ページ番号**など、テストとしての体裁に関わる基本的な要素の整合性も確実にチェックしてください。（※共通事項参照）
-   校正の際には対象が問題文であるか本文であるかを常に意識しその上で校正を行ってください
-   **誤字脱字（日本語部分含む）、番号（問題番号、ページ番号等）の整合性**といった、教材としての基本的な品質を担保するチェックも怠らないでください。（※共通事項参照）
-   教材全体の**表記（全角/半角、記号、フォントなど）やスタイルの統一性**を維持するように確認してください。（※共通事項参照）

**具体例**

#### 1. 文法・語法の正確性

-   **基本的な文法事項**:
    -   （誤）He like baseball. → （正）He like**s** baseball. （三単現のs脱落）
    -   （誤）buy egg → （正）buy egg**s** （複数形のs脱落）
    -   （指示内容）時制（現在、過去、未来、進行形、完了形など）の誤りがないか確認。
    -   （指示内容）態（能動態、受動態）の誤りがないか確認。
    -   （指示内容）疑問文、否定文の語順や助動詞の使い方が正しいか確認。
    -   （指示内容）不定詞、動名詞、分詞の用法が正しいか確認。
-   **語法（イディオム、前置詞、語の選択など）**:
    -   （指示内容）文脈に合った適切な前置詞が使われているか確認 (例: arrive **at**/ **in**)。
    -   （指示内容）不自然な語の組み合わせやイディオムの誤用がないか確認。
-   **学習指導要領・レベル**:
    -   （指示内容）対象学年で未習の文法項目や語彙が使われていないか確認。
    -   （指示内容）難易度が高すぎる、または低すぎる表現がないか確認。
-   **その他**:
    -   （指示内容）時制の一致が適切に行われているか確認。
    -   （指示内容）関係代名詞、比較級・最上級の用法が正しいか確認。

#### 2. スペル・タイポ

-   **英単語のスペルミス**:
    -   （誤）vacat**o**n → （正）vacat**io**n
    -   （誤）ex**ct**ing → （正）ex**cit**ing
-   **大文字・小文字**:
    -   （誤）**c**hinese food → （正）**C**hinese food （固有名詞）
    -   （指示内容）文頭が大文字になっているか確認。

#### 3. 表記ルール・記号の統一性

-   **句読点**:
    -   （誤）文末のピリオド(.)、クエスチョンマーク(?)脱落 → （正）適切に追加。
    -   （指示内容）コンマ(,)、アポストロフィ(')の用法が正しいか確認。
-   **記号・数字・括弧**:
    -   （指示内容）全角/半角やスタイルが教材全体で統一されているか確認。
-   **番号・参照記号**:
    -   （誤）小問番号「(4)」の重複 → （正）正しい番号に修正。
    -   （誤）注釈用アスタリスク「*」の脱落 → （正）追加。
-   **日本語部分**:
    -   （指示内容）日本語の指示文や解説の句読点（。、）が適切に使用されているか確認。

#### 4. 内容の整合性・正確性

-   **問題・解答・解説等の矛盾**:
    -   （誤）問題と解答が一致しない → （正）一致するように修正。
    -   （誤）問題と解説が一致しない → （正）一致するように修正。
    -   （指示内容）本文、設問、選択肢、指示文、リスニングスクリプト、注釈間で内容に矛盾がないか確認。
-   **解答・解説の正確性**:
    -   （誤）解説内容の誤り → （正）正しい解説に修正。
-   **設問の明確性**:
    -   （指示内容）設問の意図が曖昧でないか確認。
-   **指示との整合性**:
    -   （誤）指定語数と解答例の語数が不一致 → （正）一致するように修正。
-   **表現の自然さ**:
    -   （誤）Let's go **with me**. → （正）Let's go **together**. / **Come with me**. など自然な表現に修正。
-   **日本語部分**:
    -   （指示内容）日本語の文体（です・ます調 / だ・である調）が統一されているか確認。
-   **リスニング**:
    -   （誤）スクリプト上の話者と異なる性別のセリフ → （正）整合性を取る。

#### 5. レイアウト・体裁（可能な範囲で）

-   **解答欄**:
    -   （誤）解答欄の不足 → （正）設問数に合わせた解答欄を確保。
-   **配点**:
    -   （誤）配点合計の誤り → （正）正しい合計点に修正。
    -   （指示内容）配点表記に誤りや不整合がないか確認。
`;

// 各教科ごとのプロンプト
export const subjectPrompts: Record<SubjectType, string> = {
  japanese: guidelineJapanese,
  math: guidelineMath,
  english: guidelineEnglish,
  science: guidelineScience,
  socialStudies: guidelineSocial,
};
