import React, { useEffect, useState } from "react";

import { Button, TextArea, Tooltip } from "@spesill/components/atoms";
import { MarkdownRenderer } from "@spesill/components/organisms";

import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequestBase";
import { DocumentType } from "@spesill/models/document";

type PropsType = {
  correctedDocument: CorrectionType;
  documentType: DocumentType;
  onFocusCorrection?: (correction: CorrectionType) => void;
  onReflectCorrection?: (correction: CorrectionType) => void;
  onCancelCorrection?: (correction: CorrectionType) => void;
  onUpdateCorrection?: (correction: CorrectionType) => void;
};

export const CorrectionItem = ({
  correctedDocument,
  documentType,
  onFocusCorrection,
  onReflectCorrection,
  onCancelCorrection,
  onUpdateCorrection,
}: PropsType) => {
  const [isEditing, setIsEditing] = useState(false);
  const [suggestion, setSuggestion] = useState(
    correctedDocument.suggestionMarkdown,
  );

  useEffect(() => {
    setSuggestion(correctedDocument.suggestionMarkdown);
  }, [correctedDocument.suggestionMarkdown]);

  return (
    <div className="px-6 py-4 mt-4 space-y-2 bg-blueGray-0 rounded-md whitespace-pre-wrap relative">
      <button
        type="button"
        className="font-bold text-blueGray-600 rounded hover:bg-blueGray-100 py-1 px-0.5"
        onClick={() => {
          onFocusCorrection && onFocusCorrection(correctedDocument);
        }}
      >
        <div className="flex gap-x-2">
          <div className="bg-blueGray-400 opacity-80 rounded-full w-6 h-6 flex items-center justify-center text-white text-xs font-bold">
            {correctedDocument.index + 1}
          </div>
          {correctedDocument.location?.type === "excel" &&
            correctedDocument.location?.cellText && (
              <Tooltip
                direction={"bottom"}
                text={"校正箇所に移動"}
                className="text-xs"
              >
                <>
                  {correctedDocument.location.sheet &&
                    correctedDocument.location.sheet + " / "}
                  {correctedDocument.location.cellText}
                </>
              </Tooltip>
            )}
        </div>
      </button>
      <span className=" text-blueGray-400 ml-2">
        {correctedDocument.rule?.name}
      </span>
      <div className="py-3">
        {isEditing ? (
          <div className="flex flex-col gap-2">
            <TextArea
              value={suggestion}
              onChange={(e) => setSuggestion(e.target.value)}
              name={"suggestion"}
              className="max-h-40 overflow-y-auto"
            />
            <div className="flex space-x-1 border-t border-gray-300 pt-2">
              <Button
                onClick={() => {
                  setIsEditing(false);
                  onUpdateCorrection &&
                    onUpdateCorrection({
                      ...correctedDocument,
                      suggestionMarkdown: suggestion,
                    });
                }}
                text="編集内容を反映"
                size="small"
                color="primary"
                variant="outlined"
                className="text-xs"
              />
              <Button
                text="キャンセル"
                size="small"
                color="gray"
                variant="outlined"
                onClick={() => {
                  setIsEditing(false);
                  setSuggestion(correctedDocument.suggestionMarkdown);
                }}
                className="text-xs"
              />
            </div>
          </div>
        ) : (
          <>
            <MarkdownRenderer text={correctedDocument.suggestionMarkdown} />
            <div className="flex justify-end space-x-2 text-sm mt-4">
              <Button
                text={documentType === "pdf" ? "編集" : "置き換え"}
                color={"primary"}
                variant={"text"}
                size={"small"}
                className="border border-solid border-primary-400"
                onClick={(e) => {
                  e.preventDefault();
                  documentType === "pdf"
                    ? setIsEditing(true)
                    : onReflectCorrection &&
                      onReflectCorrection(correctedDocument);
                }}
              />
              <Button
                text={"削除"}
                color={"red"}
                variant={"outlined"}
                size={"small"}
                className="border border-solid border-blueGray-50"
                onClick={(e) => {
                  e.preventDefault();
                  onCancelCorrection && onCancelCorrection(correctedDocument);
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
