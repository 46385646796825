import React, { MouseEvent, useState } from "react";
import { toast } from "react-toastify";

import { IconButton } from "@spesill/components/molecules";

import { useAudioRecorder } from "@spesill/hooks/recordingAudio/useAudioRecorder";
import { useStorageUploadFileInChat } from "@spesill/hooks/storage/useStorageUploadFileInChat";
import { sseClient } from "@spesill/libs/sseClient";
import { User } from "@spesill/models";
interface VoiceRecorderProps {
  currentUser: User | undefined;
  chatRoomId: string;
  onTranscriptionComplete: (text: string) => void;
}

export const VoiceTranscriber: React.FC<VoiceRecorderProps> = ({
  currentUser,
  chatRoomId,
  onTranscriptionComplete,
}) => {
  const { startRecording, stopRecording, isRecording, error } =
    useAudioRecorder();
  const { uploadFile } = useStorageUploadFileInChat();
  const [isTTSProcessing, setIsTTSProcessing] = useState(false);

  const uploadAndSendPath = async (
    e: MouseEvent<HTMLButtonElement>,
    data: Blob,
  ) => {
    e.preventDefault();
    if (!currentUser || !chatRoomId) return;
    setIsTTSProcessing(true);
    const loadingToast = toast.loading("音声を変換中...");

    try {
      const uuid = crypto.randomUUID();
      const uploadedPath = `tenants/${currentUser.tenantId}/record_data/${chatRoomId}/audio/${uuid}.wav`;
      const result = await uploadFile(uploadedPath, data);

      if (!result.success) {
        toast.error("ファイルのアップロードに失敗しました");
        toast.dismiss(loadingToast);
        return;
      }

      await onSendPath(e, uploadedPath);
      toast.update(loadingToast, {
        render: "音声を変換しました",
        type: "info",
        autoClose: 2000,
        isLoading: false,
      });
    } catch (error) {
      console.error("エラーが発生しました:", error);
      toast.error("処理中にエラーが発生しました");
      toast.dismiss(loadingToast);
    } finally {
      setIsTTSProcessing(false);
    }
  };

  const onSendPath = async (e: MouseEvent<HTMLButtonElement>, path: string) => {
    e.preventDefault();
    if (!currentUser) return;

    try {
      await sseClient.sttResponse(
        "speech_to_text",
        {
          audio_path: path,
        },
        (event) => {
          try {
            const parsedData = JSON.parse(event.data);
            onTranscriptionComplete(parsedData.text);
          } catch (e) {
            console.error(e);
          }
        },
        (error) => {
          console.error("エラー:", error);
          toast.error("エラーが発生しました");
        },
      );
    } catch (error) {
      console.error("API呼び出しエラー:", error);
      toast.error("メッセージの送信中にエラーが発生しました");
    }
  };

  const handleRecordingStart = async (e: MouseEvent<HTMLButtonElement>) => {
    if (error) return toast.error(error);

    if (!isRecording) {
      toast.info("録音を開始しました");
      await startRecording();
    } else {
      const audioBlob = await stopRecording();
      await uploadAndSendPath(e, audioBlob);
    }
  };

  if (isTTSProcessing) {
    return (
      <div>
        <IconButton
          icon={{
            icon: "piSpinnerGapLight",
            size: "1rem",
            color: "text-blueGray-500",
            className: "animate-spin",
          }}
          disabled
          tooltip={{
            text: "音声を変換中...",
            direction: "top",
            className: "text-xs",
          }}
          className="p-2 hover:bg-blueGray-100 rounded-full transition-colors"
        />
      </div>
    );
  }

  return (
    <div>
      <IconButton
        icon={{
          icon: "bsFillMicFill",
          size: "1rem",
          color: isRecording ? "text-cyan-500" : "text-blueGray-500",
        }}
        onClick={handleRecordingStart}
        className="p-2 hover:bg-blueGray-100 rounded-full transition-colors"
        tooltip={{
          text: isRecording ? "録音を停止" : "音声入力",
          direction: "top",
          className: "text-xs",
        }}
      />
    </div>
  );
};
