import React, { FC, ReactNode, useState } from "react";

import { Icon } from "@spesill/components/atoms";

type Props = {
  title: ReactNode;
  description?: string;
  children: ReactNode;
  className?: string;
  defaultOpen?: boolean;
  preview?: ReactNode;
  childrenClassName?: string;
};

export const AccordionItem: FC<Props> = ({
  title,
  description,
  children,
  className = "",
  defaultOpen = false,
  preview,
  childrenClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div
      className={` rounded-lg border border-blueGray-50 bg-white ${className}`}
    >
      <button
        type="button"
        className="w-full px-4 py-3 flex items-center justify-between text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex-1">
          <div className="flex items-center gap-x-2">{title}</div>
          {description && (
            <p className="text-xs text-blueGray-400 mt-1">{description}</p>
          )}
        </div>
        <Icon
          icon="faChevronDown"
          size="1rem"
          className={`transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>

      {!isOpen && preview && <div className="px-4 pb-4 mt-1">{preview}</div>}

      {isOpen && (
        <div className={`px-4 pb-4 ${childrenClassName}`}>{children}</div>
      )}
    </div>
  );
};
