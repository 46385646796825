import React, { FC, FormEvent, MouseEvent, useMemo, useState } from "react";
import { toast } from "react-toastify";

import Link from "next/link";

import {
  Button,
  DropdownField,
  FormSubmitButton,
  Icon,
  IconType,
  Label,
  TextArea,
  TextLoadingAnimation,
  Tooltip,
  Accordion,
  AccordionItem,
} from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";
import {
  CreateEvaluateDatabaseModal,
  MarkdownRenderer,
} from "@spesill/components/organisms";

import { SubjectSelectionFeature } from "@spesill/customFeatures/subjectSelection/components/SubjectSelectionFeature";
import { subjectOptions } from "@spesill/customFeatures/subjectSelection/hooks/subjectData";
import { useSubjectSelection } from "@spesill/customFeatures/subjectSelection/hooks/useSubjectSelection";
import {
  useBoolean,
  useCurrentUser,
  useEvaluateDatabaseDropdownField,
  useFetchTenant,
  useFetchTenantDocumentSettings,
} from "@spesill/hooks";
import { CorrectionType } from "@spesill/hooks/aiRequest/useEvaluateRequestBase";
import { Document } from "@spesill/models";
import { CorrectionRule } from "@spesill/models/api/evaluate_spec";

import { CorrectionItem } from "./PDFSentenceCheck/CorrectionItem";

export type CorrectionTypeWithRule = CorrectionType & {
  rule: CorrectionRule | undefined;
};

type Props = {
  className?: string;
  document: Document;
  setCurrentMenu: (menu: "") => void;
  review?: string;
  suggestions?: string[];
  corrections?: CorrectionType[];
  rules?: CorrectionRule[];
  requestEvaluate?: (databaseId: string, proofreaderPrompt?: string) => void;
  isEvaluating?: boolean;
  onReflectCorrection?: (correction: CorrectionType) => void;
  onCancelCorrection?: (correction: CorrectionType) => void;
  onFocusCorrection?: (correction: CorrectionType) => void;
  onUpdateCorrection?: (correction: CorrectionType) => void;
  resetEvaluation?: () => void;
};

const sectionTitle = (title: string, icon: IconType) => {
  return (
    <p className="flex flex-row gap-x-2 align-center justify-center">
      <Icon icon={icon} size="2rem" color="text-primary-400" />
      <span className="text-h6 text-primary-400">{title}</span>
    </p>
  );
};

const LoadingComponent = ({
  title,
  icon,
}: {
  title: string;
  icon: IconType;
}) => {
  return (
    <>
      {sectionTitle(title, icon)}
      <div className="px-6 py-4 bg-blueGray-0 rounded-md whitespace-pre-wrap text-sm max-h-96 overflow-y-auto">
        <TextLoadingAnimation />
      </div>
    </>
  );
};

export const AiSentenceCheckMenu: FC<Props> = ({
  className = "",
  setCurrentMenu,
  review,
  suggestions,
  corrections,
  requestEvaluate,
  isEvaluating,
  onCancelCorrection,
  onReflectCorrection,
  onFocusCorrection,
  onUpdateCorrection,
  resetEvaluation,
  document,
  rules,
}) => {
  const { currentUser, currentPlan } = useCurrentUser();
  const { tenant } = useFetchTenant();
  const { tenantDocumentSettings } = useFetchTenantDocumentSettings();
  const documentType = useMemo(() => document.documentType, [document]);

  // 機能フラグを取得（DBから取得した結果を使用）
  const isSubjectFeatureEnabled = useMemo(() => {
    return tenant?.customFeatures?.subject_prompt_selection || false;
  }, [tenant]);

  const isFreePlan = useMemo(() => currentPlan === "FREE", [currentPlan]);
  const {
    isChecked: isCreateEvaluateDatabaseModalOpen,
    setTrue: setCreateEvaluateDatabaseModalOpen,
    setFalse: setCreateEvaluateDatabaseModalClose,
  } = useBoolean(false);

  const {
    dropdownValue: dropdownValueEvaluateDatabase,
    dropdownSelectableValue: dropdownSelectableValueEvaluateDatabase,
    onChange: onChangeDropdownEvaluateDatabase,
    fetchEvaluateDatabases: refreshOptionsEvaluateDatabase,
  } = useEvaluateDatabaseDropdownField();
  const handleOnClose = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCurrentMenu("");
  };

  const [evaluationPrompt, setEvaluationPrompt] = useState<string>(
    tenantDocumentSettings?.customEvaluationPrompt || "",
  );

  // 教科選択機能のロジックをカスタムフックに分離
  const {
    selectedSubject,
    isEnabled: isSubjectModeEnabled,
    handleSubjectChange,
    toggleSubjectMode,
    getFinalPrompt,
  } = useSubjectSelection({
    isFeatureEnabled: isSubjectFeatureEnabled,
    initialPrompt: tenantDocumentSettings?.customEvaluationPrompt || "",
    onPromptChange: setEvaluationPrompt,
  });

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentUser) return;
    if (!dropdownValueEvaluateDatabase) {
      toast.error("評価データベースを選択してください。");
      return;
    }

    // 最終プロンプトを取得
    const finalPrompt = getFinalPrompt(evaluationPrompt);

    requestEvaluate &&
      requestEvaluate(dropdownValueEvaluateDatabase?.value || "", finalPrompt);
  };

  // 表示モードの切り替えを追加します
  const [displayMode, setDisplayMode] = useState<"category" | "list">("list");

  // 表示モード切り替えボタンを追加
  const DisplayModeToggle = () => {
    return (
      <div className="flex justify-center mb-2 mx-auto">
        <div className="flex border rounded-md overflow-hidden">
          <button
            type="button"
            className={`px-3 py-1 text-sm ${
              displayMode === "list"
                ? "bg-primary-400 text-white"
                : "bg-white text-blueGray-600"
            }`}
            onClick={() => setDisplayMode("list")}
          >
            一覧表示
          </button>
          <button
            type="button"
            className={`px-3 py-1 text-sm ${
              displayMode === "category"
                ? "bg-primary-400 text-white"
                : "bg-white text-blueGray-600"
            }`}
            onClick={() => setDisplayMode("category")}
          >
            カテゴリ表示
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <form
        className={`p-6 flex flex-col border-b border-solid border-blueGray-50 h-full overflow-scroll ${className}`}
        onSubmit={handleOnSubmit}
      >
        {resetEvaluation && (review || suggestions || corrections) && (
          <div className="flex justify-end">
            <IconButton
              icon={{
                icon: "aiOutlineReload",
                size: "1.2rem",
                color: "text-blueGray-400",
              }}
              tooltip={{
                direction: "bottom",
                text: "リセット",
              }}
              onClick={resetEvaluation}
            />
          </div>
        )}
        <div className="flex flex-col grow gap-y-10">
          {!isEvaluating && !review && (
            <div className="flex flex-col gap-y-6">
              <Label
                htmlFor={"evaluationDatabase"}
                labelText={"AI評価"}
                className="font-bold"
                required={false}
              />
              <p className="text-body-base">
                文章チェックするための評価データベースをセットアップしてください。
              </p>

              <div className="flex flex-col gap-y-2 w-full">
                <DropdownField
                  name="newEvaluateDatabaseID"
                  value={dropdownValueEvaluateDatabase}
                  options={dropdownSelectableValueEvaluateDatabase}
                  onChange={(newValue, meta) => {
                    if (meta.action !== "create-option" && newValue) {
                      onChangeDropdownEvaluateDatabase(newValue);
                    }
                  }}
                  height="47px"
                  width="16rem"
                  isClearable={false}
                  placeholder="評価データベースを選択"
                  customMenuPosition="bottom"
                  customMenu={() =>
                    isFreePlan ? (
                      <Tooltip
                        direction="right"
                        className="text-sm"
                        contentChildren={
                          <div>
                            現在のプランではご利用できません。
                            <br />
                            プランの切り替えは
                            <Link className="border-b" href={"/settings/plan"}>
                              こちら
                            </Link>
                          </div>
                        }
                      >
                        <Button
                          text="データベースを新規作成"
                          color="primary"
                          variant="text"
                          className="text-body-base w-full"
                          icon={{
                            icon: "aiOutlinePlus",
                            size: "1rem",
                            color: "text-primary-400",
                          }}
                          disabled
                          onClick={() => 0}
                        />
                      </Tooltip>
                    ) : (
                      <Button
                        text="データベースを新規作成"
                        color="primary"
                        variant="text"
                        className="text-body-base w-full"
                        icon={{
                          icon: "aiOutlinePlus",
                          size: "1rem",
                          color: "text-primary-400",
                        }}
                        onClick={() => {
                          setCreateEvaluateDatabaseModalOpen();
                        }}
                      />
                    )
                  }
                />
              </div>

              {/* 特定のテナントでのみ教科選択モードの切り替えスイッチを表示 */}
              {isSubjectFeatureEnabled && (
                <div className="flex items-center gap-x-2 mt-2">
                  <input
                    type="checkbox"
                    id="subjectModeToggle"
                    checked={isSubjectModeEnabled}
                    onChange={toggleSubjectMode}
                    className="form-checkbox h-4 w-4 text-primary-400 rounded"
                  />
                  <label
                    htmlFor="subjectModeToggle"
                    className="text-sm font-medium"
                  >
                    教科選択を有効にする
                  </label>
                </div>
              )}

              {/* 分離したコンポーネントを使用 */}
              <SubjectSelectionFeature
                isEnabled={isSubjectFeatureEnabled && isSubjectModeEnabled}
                selectedSubject={selectedSubject}
                onSubjectChange={handleSubjectChange}
                subjectOptions={subjectOptions}
              />

              <div className="flex flex-col gap-y-2 w-full">
                <div className="flex gap-x-2">
                  <Label
                    htmlFor={"evaluationPrompt"}
                    labelText={
                      isSubjectModeEnabled
                        ? "補足校正ルール"
                        : "カスタム校正ルール"
                    }
                    className="font-bold"
                    required={false}
                  />
                </div>
                <p className="text-sm text-blueGray-400">
                  {isSubjectModeEnabled
                    ? "選択した教科の校正ルールに加えて、さらに補足したいルールがあれば入力してください。"
                    : "文書全体に対して、AIに校正のルールなどを命令することができます。"}
                </p>
                <TextArea
                  name={"evaluationPrompt"}
                  value={evaluationPrompt}
                  onChange={(e) => setEvaluationPrompt(e.target.value)}
                  maxRows={20}
                  rows={10}
                  placeholder={
                    isSubjectModeEnabled
                      ? "補足のルールを入力してください"
                      : "校正のルールをAIに指示したい場合はこちらに入力してください。"
                  }
                />
              </div>
              <div className="flex justify-end">
                <FormSubmitButton
                  text={isEvaluating ? "評価中..." : "評価開始"}
                  color="primary"
                  variant="contained"
                  disabled={
                    isEvaluating || (isSubjectModeEnabled && !selectedSubject)
                  }
                  className="ml-4"
                />
              </div>
            </div>
          )}

          <div className="flex flex-col gap-y-6">
            {review ? (
              <div className="flex flex-col gap-y-2">
                {sectionTitle("AI評価", "aiOutlineCheckCircle")}

                <div className="px-6 py-4 bg-blueGray-0 rounded-md whitespace-pre-wrap max-h-96 overflow-y-auto text-sm">
                  <MarkdownRenderer text={review} />
                </div>
              </div>
            ) : (
              isEvaluating && (
                <LoadingComponent title={"AI評価"} icon={"aiOutlineFileText"} />
              )
            )}

            {suggestions && suggestions.length > 0 ? (
              <div className="flex flex-col gap-y-2">
                {sectionTitle("改善提案", "mdOutlineComment")}

                <div className="space-y-4 max-h-96 overflow-y-auto text-sm">
                  {suggestions.map((suggestion: string, index: number) => (
                    <div
                      key={index}
                      className="px-6 py-4 bg-blueGray-0 rounded-md shadow-sm whitespace-pre-wrap"
                    >
                      <MarkdownRenderer text={suggestion} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              isEvaluating && (
                <LoadingComponent
                  title={"改善提案"}
                  icon={"mdOutlineComment"}
                />
              )
            )}
            {corrections && corrections?.length > 0 ? (
              <div className="flex flex-col gap-y-2">
                {sectionTitle("校正", "mdSpellcheck")}
                <DisplayModeToggle />
                <div className="max-h-96 overflow-y-auto space-y-4 text-sm">
                  {displayMode === "category" ? (
                    <Accordion>
                      {rules?.map((rule, index) => {
                        const targetCorrections = corrections.filter(
                          (correction) => correction.rule.id === rule.id,
                        );
                        return (
                          <AccordionItem
                            key={rule.id || index}
                            title={
                              <div className="flex items-center justify-between">
                                <span className="font-medium">
                                  {rule.id + "." + rule.name}
                                </span>
                                <span className="text-xs text-blueGray-400">
                                  {targetCorrections?.length}件
                                </span>
                              </div>
                            }
                            description={rule.description}
                            childrenClassName="max-h-80 overflow-y-auto"
                          >
                            <div className="space-y-4 pt-4">
                              {targetCorrections?.map(
                                (correction, corrIndex) => (
                                  <CorrectionItem
                                    key={corrIndex}
                                    correctedDocument={correction}
                                    documentType={documentType || "word"}
                                    onFocusCorrection={onFocusCorrection}
                                    onReflectCorrection={onReflectCorrection}
                                    onCancelCorrection={onCancelCorrection}
                                    onUpdateCorrection={onUpdateCorrection}
                                  />
                                ),
                              )}
                            </div>
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                  ) : (
                    <div className="space-y-4">
                      {corrections?.map((correction, index) => (
                        <CorrectionItem
                          key={index}
                          correctedDocument={correction}
                          documentType={documentType || "word"}
                          onFocusCorrection={onFocusCorrection}
                          onReflectCorrection={onReflectCorrection}
                          onCancelCorrection={onCancelCorrection}
                          onUpdateCorrection={onUpdateCorrection}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : corrections?.length === 0 &&
              suggestions &&
              suggestions?.length > 0 ? (
              <>
                {sectionTitle("校正", "mdSpellcheck")}
                <p className="px-6 py-4  text-center rounded-md whitespace-pre-wrap p-4">
                  校正が必要な箇所はありません。
                </p>
              </>
            ) : (
              isEvaluating && (
                <LoadingComponent title={"校正"} icon={"mdSpellcheck"} />
              )
            )}
          </div>
        </div>

        <div className="border-t border-solid border-blueGray-50 pt-4 mt-4 flex justify-end gap-x-4">
          <Button
            text="キャンセル"
            color="gray"
            variant="contained"
            onClick={handleOnClose}
          />
        </div>
      </form>
      {isCreateEvaluateDatabaseModalOpen && (
        <CreateEvaluateDatabaseModal
          onClose={setCreateEvaluateDatabaseModalClose}
          onSuccess={async (id) => {
            const res = await refreshOptionsEvaluateDatabase();
            if (!res) return;
            const item = res.find((e) => e.id === id);
            if (!item) return;
            onChangeDropdownEvaluateDatabase({
              value: item.id,
              label: item.systemName,
            });
          }}
        />
      )}
    </>
  );
};
