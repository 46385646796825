import React, { ComponentProps, FC, ReactNode, useEffect } from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import { Alert } from "@spesill/components/atoms";
import { OriginalHead } from "@spesill/components/layouts";
import { Sidebar, Header } from "@spesill/components/molecules";

type PropsType = {
  children: ReactNode;
  alert?: ComponentProps<typeof Alert>;
};

export const ChatLayout: FC<PropsType> = ({ children, alert }) => {
  const router = useRouter();

  useEffect(() => {
    const fetchCurrentUser = () => {
      try {
        /* empty */
      } catch (e) {
        toast.error("アクセス権限がありません");
        router.isReady && router.push("/sign_in");
      }
    };
    fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OriginalHead />
      <main className="flex max-h-screen w-full">
        <Sidebar className="shrink-0 w-[15rem]" />
        <div className="w-full flex flex-col max-h-screen overflow-y-auto bg-blueGray-0">
          <Header alert={alert} />
          <div className="flex-1 overflow-hidden">{children}</div>
        </div>
      </main>
    </>
  );
};
