import { useRef } from "react";
import { toast } from "react-toastify";

import { useStorageUploadFileInChat } from "@spesill/hooks/storage/useStorageUploadFileInChat";
import { sseClient } from "@spesill/libs/sseClient";

export interface UploadProgressCallback {
  onProgress?: (progress: boolean) => void;
  onComplete?: (path: string) => void;
  onError?: () => void;
}

export const useFileUpload = (tenantId?: string, chatRoomId?: string) => {
  const { uploadFile } = useStorageUploadFileInChat();
  const abortControllerRef = useRef<AbortController | null>(null);

  if (!tenantId || !chatRoomId) {
    return {
      uploadVideo: null,
      uploadImage: null,
      cancelUpload: null,
    };
  }

  const createPath = (type: "image" | "video", fileName: string): string => {
    return `tenants/${tenantId}/attached_data/${chatRoomId}/${type}/${fileName}`;
  };

  const uploadVideo = async (
    file: File,
    callbacks: UploadProgressCallback = {},
  ): Promise<void> => {
    const uploadedPath = createPath("video", file.name);

    try {
      abortControllerRef.current = new AbortController();
      const result = await uploadFile(uploadedPath, file);

      if (!result.success) {
        toast.error("ファイルのアップロードに失敗しました");
        callbacks.onError?.();
        return;
      }

      callbacks.onProgress?.(true);

      await sseClient.videoPathResponse(
        "video_upload",
        { path: uploadedPath },
        (event) => {
          callbacks.onProgress?.(false);
          // eslint-disable-next-line quotes
          const finalPath = (event.data || uploadedPath).replaceAll('"', "");
          callbacks.onComplete?.(finalPath);
        },
        (error) => {
          console.error("アップロード:", error);
          toast.error("動画のアップロード中にエラーが発生しました");
          callbacks.onError?.();
        },
      );
    } catch (error) {
      console.error("API呼び出しエラー:", error);
      toast.error("メッセージの送信中にエラーが発生しました");
      callbacks.onError?.();
    }
  };

  const uploadImage = async (
    file: File,
    callbacks: UploadProgressCallback = {},
  ): Promise<void> => {
    try {
      abortControllerRef.current = new AbortController();
      const uploadedPath = createPath("image", file.name);

      callbacks.onProgress?.(true);
      const result = await uploadFile(uploadedPath, file);

      if (!result.success) {
        toast.error("ファイルのアップロードに失敗しました");
        callbacks.onError?.();
        return;
      }

      callbacks.onProgress?.(false);
      callbacks.onComplete?.(uploadedPath);
    } catch (error) {
      console.error("エラーが発生しました:", error);
      toast.error("処理中にエラーが発生しました");
      callbacks.onError?.();
    }
  };

  const cancelUpload = (): void => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  return {
    uploadVideo,
    uploadImage,
    cancelUpload,
  };
};
