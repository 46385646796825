import {
  CorrectionRule,
  CorrectResponseType,
} from "@spesill/models/api/evaluate_spec";

import {
  useEvaluateRequestBase,
  CorrectionType,
} from "./useEvaluateRequestBase";

export const useEvaluateRequestPdf = (
  tenantId: string,
  documentPath: string,
) => {
  const base = useEvaluateRequestBase(tenantId, documentPath);

  function createCorrectionsFromResponse(
    correctResponses: CorrectResponseType[],
    rules: CorrectionRule[],
  ): CorrectionType[] {
    return correctResponses.map((correctResponse, index) => {
      const suggestionMarkdown = correctResponse.suggestion;
      const originText = correctResponse.originalText;
      const replacingText = correctResponse.suggestion
        .replace(/~~[^~]+~~/g, "")
        .replace(/\*\*/g, "");
      const comment = correctResponse.comment;
      const reference = correctResponse.originalText;
      const rule = rules.find((rule) => rule.id === correctResponse.ruleId) ?? {
        id: "others",
        name: "その他",
        description: "",
      };

      return {
        suggestionMarkdown,
        originText,
        replacingText,
        reference,
        comment,
        index,
        rule,
      };
    });
  }

  const handleCorrections = (
    correctResponses: CorrectResponseType[],
    rules: CorrectionRule[],
  ) => {
    const corrections = createCorrectionsFromResponse(correctResponses, rules);
    base.setCorrections(corrections);
    return corrections;
  };

  const requestEvaluatePdf = async (
    databaseId: string,
    proofreaderPrompt?: string,
    documentKind?: string,
  ) => {
    const res = await base.requestEvaluate(
      databaseId,
      proofreaderPrompt,
      documentKind,
    );
    if (res) {
      const corrections = handleCorrections(res.corrections, res.rules);
      return { ...res, corrections };
    }
  };

  return {
    ...base,
    requestEvaluate: requestEvaluatePdf,
  };
};
