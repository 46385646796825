import { useState, useCallback, useRef } from "react";

interface UseAudioRecorderReturn {
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<Blob>;
  isRecording: boolean;
  error: string | null;
}

export const useAudioRecorder = (): UseAudioRecorderReturn => {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const workletNodeRef = useRef<AudioWorkletNode | null>(null);
  const audioChunksRef = useRef<Float32Array[]>([]);

  const resampleAudio = useCallback(
    (
      audioData: Float32Array[],
      originalSampleRate: number,
      targetSampleRate: number,
    ): Float32Array => {
      if (!audioData.length) {
        throw new Error("オーディオデータが空です");
      }

      const totalLength = audioData.reduce((acc, curr) => acc + curr.length, 0);
      const combinedData = new Float32Array(totalLength);

      let offset = 0;
      for (const chunk of audioData) {
        if (!(chunk instanceof Float32Array)) {
          throw new Error("Invalid audio chunk type");
        }
        if (offset + chunk.length <= combinedData.length) {
          combinedData.set(chunk, offset);
          offset += chunk.length;
        } else {
          throw new Error("Buffer overflow while combining audio chunks");
        }
      }

      if (combinedData.length === 0) {
        throw new Error("結合されたオーディオデータが空です");
      }

      const ratio = originalSampleRate / targetSampleRate;
      const newLength = Math.round(combinedData.length / ratio);
      const result = new Float32Array(newLength);

      for (let i = 0; i < newLength; i++) {
        const position = i * ratio;
        const index = Math.floor(position);
        const nextIndex = Math.min(index + 1, combinedData.length - 1);
        const decimal = position - index;

        const currentSample = combinedData[index];
        const nextSample = combinedData[nextIndex];

        if (
          typeof currentSample !== "number" ||
          typeof nextSample !== "number"
        ) {
          throw new Error(
            `Invalid sample data at index ${index} or ${nextIndex}`,
          );
        }

        result[i] = currentSample * (1 - decimal) + nextSample * decimal;
      }

      return result;
    },
    [],
  );

  const createWavBlob = useCallback(
    (audioData: Float32Array[], originalSampleRate: number): Blob => {
      const TARGET_SAMPLE_RATE = 16000;

      if (!audioData.length) {
        throw new Error("オーディオデータが空です");
      }

      const resampledData = resampleAudio(
        audioData,
        originalSampleRate,
        TARGET_SAMPLE_RATE,
      );

      if (resampledData.length === 0) {
        throw new Error("リサンプリング後のデータが空です");
      }

      const HEADER_SIZE = 44;
      const dataSize = resampledData.length * 2;
      const buffer = new ArrayBuffer(HEADER_SIZE + dataSize);
      const view = new DataView(buffer);

      const writeString = (offset: number, string: string) => {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      };

      writeString(0, "RIFF");
      view.setUint32(4, 36 + dataSize, true);
      writeString(8, "WAVE");

      writeString(12, "fmt ");
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true);
      view.setUint16(22, 1, true);
      view.setUint32(24, TARGET_SAMPLE_RATE, true);
      view.setUint32(28, TARGET_SAMPLE_RATE * 2, true);
      view.setUint16(32, 2, true);
      view.setUint16(34, 16, true);

      writeString(36, "data");
      view.setUint32(40, dataSize, true);

      let offset = 44;
      for (let i = 0; i < resampledData.length; i++) {
        const currentSample = resampledData[i];
        if (typeof currentSample !== "number") {
          throw new Error(`Invalid sample at index ${i}`);
        }
        const sample = Math.max(-1, Math.min(1, currentSample));
        const value = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
        view.setInt16(offset, value, true);
        offset += 2;
      }

      return new Blob([buffer], { type: "audio/wav" });
    },
    [resampleAudio],
  );

  const startRecording = useCallback(async () => {
    try {
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      const audioContext = new AudioContext();
      await audioContext.audioWorklet.addModule("/audio-processor.js");

      const source = audioContext.createMediaStreamSource(stream);
      const workletNode = new AudioWorkletNode(
        audioContext,
        "audio-recorder-processor",
      );

      workletNode.port.onmessage = (event) => {
        if (event.data.eventType === "audioData") {
          audioChunksRef.current.push(event.data.audioData);
        }
      };

      source.connect(workletNode);
      workletNode.connect(audioContext.destination);

      audioContextRef.current = audioContext;
      workletNodeRef.current = workletNode;
      setIsRecording(true);
      audioChunksRef.current = [];
    } catch (err) {
      setError(err instanceof Error ? err.message : "録音の開始に失敗しました");
    }
  }, []);

  const stopRecording = useCallback(async (): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      try {
        if (
          audioContextRef.current &&
          workletNodeRef.current &&
          streamRef.current
        ) {
          const originalSampleRate = audioContextRef.current.sampleRate;
          const tracks = streamRef.current.getTracks();
          tracks.forEach((track) => track.stop());

          workletNodeRef.current.disconnect();
          audioContextRef.current.close();

          const blob = createWavBlob(
            audioChunksRef.current,
            originalSampleRate,
          );

          audioContextRef.current = null;
          workletNodeRef.current = null;
          streamRef.current = null;
          audioChunksRef.current = [];
          setIsRecording(false);

          resolve(blob);
        } else {
          reject(new Error("録音が開始されていません"));
        }
      } catch (err) {
        reject(err);
      }
    });
  }, [createWavBlob]);

  return {
    startRecording,
    stopRecording,
    isRecording,
    error,
  };
};
