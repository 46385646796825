import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import "katex/dist/katex.min.css";

// TeX記法をレンダリングできる記法（$）に変換する関数
const transformTeX = (text: string): string => {
  // \( ... \) を $...$ に変換
  text = text.replace(/\\\(([^()]+)\\\)/g, (_, group) => `$${group}$`);

  // \[ ... \] を $$...$$ に変換
  text = text.replace(/\\\[(.*?)\\\]/gs, (_, group) => `$$${group}$$`);

  // \begin{equation} ... \end{equation} を $$...$$ に変換
  text = text.replace(
    /\\begin\{equation\}(.*?)\\end\{equation\}/gs,
    (_, group) => `$$${group}$$`,
  );

  return text;
};

export const MarkdownRenderer: React.FC<{ text: string }> = ({ text }) => {
  const transformedText = transformTeX(text);
  return (
    <ReactMarkdown
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex]}
      components={{
        h1: ({ children }) => (
          <h1 className="text-blueGray-800 font-bold text-2xl">{children}</h1>
        ),
        h2: ({ children }) => (
          <h2 className="text-blueGray-800 font-bold text-xl">{children}</h2>
        ),
        h3: ({ children }) => (
          <h3 className="text-blueGray-800 font-bold text-lg">{children}</h3>
        ),
        h4: ({ children }) => (
          <h4 className="text-blueGray-800 font-bold">{children}</h4>
        ),
        h5: ({ children }) => (
          <h5 className="text-blueGray-800 font-bold">{children}</h5>
        ),
        h6: ({ children }) => (
          <h6 className="text-blueGray-800 font-bold">{children}</h6>
        ),
        code: ({ children }) => {
          return (
            <pre className="bg-blueGray-700 text-white p-2 rounded">
              <code>{children}</code>
            </pre>
          );
        },
        del: ({ children, ...props }) => (
          <del
            style={{
              color: "red",
              textDecoration: "line-through",
              backgroundColor: "rgba(255, 0, 0, 0.1)",
              padding: "0 2px",
              borderRadius: "2px",
            }}
            {...props}
          >
            {children}
          </del>
        ),
        a: ({ children, ...props }) => (
          <a className="text-primary-500 underline" {...props}>
            {children}
          </a>
        ),
        img: ({ src, alt, ...props }) => <img src={src} alt={alt} {...props} />,
        table: ({ children, ...props }) => (
          <table
            className="border-collapse border border-blueGray-300"
            {...props}
          >
            {children}
          </table>
        ),
        thead: ({ children, ...props }) => (
          <thead className=" bg-blueGray-50 bg-opacity-50" {...props}>
            {children}
          </thead>
        ),
        tbody: ({ children, ...props }) => (
          <tbody className="" {...props}>
            {children}
          </tbody>
        ),
        tr: ({ children, ...props }) => (
          <tr className="border-b border-gray-300" {...props}>
            {children}
          </tr>
        ),
        th: ({ children, ...props }) => (
          <th className="border border-gray-300 p-2" {...props}>
            {children}
          </th>
        ),
        td: ({ children, ...props }) => (
          <td className="border border-gray-300 p-2" {...props}>
            {children}
          </td>
        ),
        blockquote: ({ children, ...props }) => (
          <blockquote className="border-l-2 border-gray-300 pl-4" {...props}>
            {children}
          </blockquote>
        ),
        em: ({ children, ...props }) => (
          <em className="italic" {...props}>
            {children}
          </em>
        ),
        ul: ({ children, ...props }) => (
          <ul className="list-disc pl-4" {...props}>
            {children}
          </ul>
        ),
        ol: ({ children, ...props }) => (
          <ol className="list-decimal pl-4" {...props}>
            {children}
          </ol>
        ),
        li: ({ children, ...props }) => {
          return (
            <li className="pl-1" {...props}>
              <div className="flex flex-col">{children}</div>
            </li>
          );
        },
        hr: ({ ...props }) => (
          <hr className="border-gray-300 my-4" {...props} />
        ),
      }}
    >
      {transformedText}
    </ReactMarkdown>
  );
};
