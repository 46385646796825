import { useState } from "react";

import { cellIndexType } from "@spesill/components/organisms/Document/DocumentEditor/DocumentExcelEditor/DocumentExcelEditor";

import { useBoolean } from "../useBoolean";
export type AiRequestMenuType =
  | "createText"
  | "refineConcretize"
  | "refineSummarize";

export type ActiveTextBoxType = {
  name: string;
  text: string;
};
export type GenerationModeType = "cell" | "textBox";

export const useExcelEditorState = () => {
  const [generationMode, setGenerationMode] =
    useState<GenerationModeType>("cell");
  const [activeCell, setActiveCell] = useState<cellIndexType | null>(null);
  const [activeCells, setActiveCells] = useState<cellIndexType[]>([]);
  const [activeTextBox, setActiveTextBox] = useState<ActiveTextBoxType | null>(
    null,
  );
  const [selectAiRequestMenu, setSelectAiRequestMenu] =
    useState<AiRequestMenuType>("createText");

  const {
    isChecked: isOpenInsertForm,
    setFalse: closeInsertForm,
    setTrue: openInsertForm,
  } = useBoolean(false);

  const {
    isChecked: isAiUsed,
    setFalse: setAiUsedFalse,
    setTrue: setAiUsedTrue,
  } = useBoolean(false);

  const {
    isChecked: isChanged,
    setTrue: setIsChanged,
    setFalse: resetIsChanged,
  } = useBoolean(false);

  const {
    isChecked: isSaving,
    setTrue: setSavingTrue,
    setFalse: setSavingFalse,
  } = useBoolean(false);

  const {
    isChecked: isRegisterToLearningDatabase,
    onChange: onChangeRegisterToLearningDatabase,
  } = useBoolean(false);

  const handleCloseForm = () => {
    closeInsertForm();
    setAiUsedFalse();
  };

  return {
    selectAiRequestMenu,
    setSelectAiRequestMenu,
    activeCell,
    setActiveCell,
    activeCells,
    setActiveCells,
    activeTextBox,
    setActiveTextBox,
    generationMode,
    setGenerationMode,
    isOpenInsertForm,
    closeInsertForm,
    openInsertForm,
    isAiUsed,
    setAiUsedFalse,
    setAiUsedTrue,
    isChanged,
    setIsChanged,
    resetIsChanged,
    isSaving,
    setSavingTrue,
    setSavingFalse,
    isRegisterToLearningDatabase,
    onChangeRegisterToLearningDatabase,
    handleCloseForm,
  };
};
