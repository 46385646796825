import { useCallback } from "react";

import { privateStorage } from "@spesill/config/firebase";
import { ref, uploadBytes } from "@spesill/libs/firebase";

interface UploadSuccess {
  success: true;
  path: string;
}

interface UploadFailure {
  success: false;
  error: unknown;
}

type UploadResult = UploadSuccess | UploadFailure;

export const useStorageUploadFileInChat = () => {
  const uploadFile = useCallback(
    async (storagePath: string, file: File | Blob): Promise<UploadResult> => {
      try {
        const fileRef = ref(privateStorage, storagePath);
        const snapshot = await uploadBytes(fileRef, file);

        return {
          success: true,
          path: snapshot.ref.fullPath,
        };
      } catch (error) {
        console.error("ファイルのアップロード中にエラーが発生しました:", error);
        return {
          success: false,
          error,
        };
      }
    },
    [],
  );

  return { uploadFile };
};
