import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import {
  useEditor,
  EditorContent,
  ReactNodeViewRenderer,
  NodeViewProps,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import htmlToDocx from "html-to-docx";
import React, { MouseEvent, useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  DropdownField,
  SingleValueType,
} from "@spesill/components/atoms";
import { LabelWithCheckbox } from "@spesill/components/molecules";
import { RightFloatSidebar } from "@spesill/components/organisms";

import {
  useBoolean,
  useRegisterDocumentToLearningDatabase,
  useInsertTiptapNode,
} from "@spesill/hooks";
import { useEvaluateRequestWord } from "@spesill/hooks";
import { Document } from "@spesill/models";

import { TiptapBubbleMenu } from "./BubbleMenu/BubbleMenu";
import { TiptapEditorToolbar } from "./TiptapEditorToolBar";
import { TiptapFlowchartExtension } from "./TiptapFlowchartExtension";
import { HeadingWithMenu } from "./TiptapHeadingWithMenu";
import { ProofReaderExtension } from "./TiptapProofReaderExtension";
import { TiptapPunchLayoutExtension } from "./TiptapPunchLayoutExtension";

type PropsType = {
  className?: string;
  html: string;
  handleUploadFile?: (html: string) => Promise<void>;
  isEditor?: boolean;
  currentDatabaseValue?: SingleValueType;
  isFreePlan?: boolean;
  fileName?: string;
  document: Document;
};

export const TiptapDocumentWordEditor = ({
  className,
  html,
  handleUploadFile,
  isFreePlan,
  isEditor = false,
  currentDatabaseValue,
  fileName = "",
  document,
}: PropsType) => {
  const { isChecked: isChanged, setTrue, setFalse } = useBoolean(false);
  const [currentHtml, setCurrentHtml] = useState(html);

  const {
    isChecked: isSaving,
    setTrue: setSavingTrue,
    setFalse: setSavingFalse,
  } = useBoolean(false);
  const {
    isChecked: isRegisterToLearningDatabase,
    onChange: onChangeRegisterToLearningDatabase,
  } = useBoolean(false);
  const {
    dropdownValueLearningDatabase,
    dropdownSelectableValueLearningDatabase,
    onChangeDropdownLearningDatabase,
    handleRegisterDocument,
  } = useRegisterDocumentToLearningDatabase({
    systemName: fileName,
    html,
    currentDatabaseValue,
    htmlToDocx,
  });

  const HeadingWithMenuNodeViewRenderer = (props: NodeViewProps) => {
    const { editor, node, getPos } = props;
    const { insertHtml, insertText, insertChartValue } = useInsertTiptapNode(
      editor,
      learningDatabaseId,
      documentKind,
      freeInputKind,
    );

    const insertValue = (
      value: string,
      isHtml?: boolean,
      isChart?: boolean,
    ) => {
      if (!editor) return;

      const pos = getPos() + node.nodeSize;
      if (isHtml) {
        isChart ? insertChartValue(value, pos) : insertHtml(value, pos);
        return;
      } else {
        insertText(value, pos);
        return;
      }
    };

    return (
      <HeadingWithMenu
        node={node}
        documentInfo={document}
        insertValue={insertValue}
      />
    );
  };

  const CustomHeading = Heading.extend({
    addNodeView() {
      return ReactNodeViewRenderer(HeadingWithMenuNodeViewRenderer);
    },
  });

  const learningDatabaseId = currentDatabaseValue?.value;
  const documentKind = document.kind;
  const freeInputKind = document.freeInputKind;

  const editor = useEditor(
    {
      content: currentHtml,
      editable: isEditor,
      extensions: [
        StarterKit,
        Paragraph,
        Text,
        Table.configure({
          resizable: true,
        }),
        TableCell,
        TableHeader,
        TableRow,
        Underline,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Image.configure({
          inline: true,
          allowBase64: true,
        }),
        Link.configure({
          openOnClick: false,
          autolink: true,
        }),
        isEditor
          ? CustomHeading.configure({
              levels: [1, 2, 3, 4, 5],
            })
          : Heading.configure({
              levels: [1, 2, 3, 4, 5],
            }),
        TiptapFlowchartExtension({
          learningDatabaseId,
          documentKind,
          freeInputKind,
        }),
        TiptapPunchLayoutExtension({
          learningDatabaseId,
        }),
        ProofReaderExtension,
      ],
      onUpdate: () => {
        setTrue();
      },
      onBlur: (props) => {
        setCurrentHtml(props.editor.getHTML());
      },
    },
    [learningDatabaseId, documentKind, freeInputKind],
  );
  const {
    requestEvaluate,
    review,
    suggestions,
    corrections,
    rules,
    isEvaluating,
    onCancelCorrection,
    onReflectCorrection,
    resetEvaluation,
  } = useEvaluateRequestWord(editor, document.tenantId, document.path);

  const onSaveFile = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!handleUploadFile || !editor) return;
    if (corrections && corrections.length > 0) {
      toast.warn(
        "校正中です。エディターもしくは右のアイコンから校正を完了してください",
      );
      return;
    }

    setSavingTrue();
    const uploadStatus = toast.loading(
      "アップロード中です。しばらくお待ちください",
    );
    try {
      if (isRegisterToLearningDatabase) {
        if (!dropdownValueLearningDatabase?.value) {
          toast.error("データベースを選択してください。");
          return;
        }
        await handleRegisterDocument();
      }
      await handleUploadFile(editor.getHTML());
      setFalse();
      toast.update(uploadStatus, {
        render: "アップロードが完了しました。",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch {
      toast.error("アップロードに失敗しました。");
      toast.dismiss(uploadStatus);
    } finally {
      setSavingFalse();
    }
  };

  const handleRequestEvaluate = async (
    databaseId: string,
    proofreaderPrompt?: string,
  ) => {
    await requestEvaluate(
      databaseId,
      proofreaderPrompt,
      document.getDisplayDocumentKind(),
    );
  };

  return (
    <div className={`relative ${className}`}>
      {isEditor && <TiptapEditorToolbar editor={editor} />}
      {editor && (
        <TiptapBubbleMenu
          editor={editor}
          learningDatabaseId={learningDatabaseId || ""}
          documentKind={documentKind || "specification"}
          freeInputKind={freeInputKind}
          systemName={document.systemName}
          documentInfo={document}
        />
      )}
      <EditorContent
        className="max-h-[90vh] bg-white overflow-y-auto mx-auto whitespace-pre-wrap"
        editor={editor}
      />
      {/* NOTE: 初めに受け取ったHTMLが変化したら保存ボタンを表示 */}
      {handleUploadFile && isEditor && isChanged && (
        <div className="sticky bottom-0 w-full">
          <div className="bg-primary-400 w-full py-4 opacity-90 flex flex-row justify-between items-center pl-6 pr-20">
            <Button
              text="編集内容を保存"
              color="primary"
              variant="contained"
              onClick={onSaveFile}
              disabled={isSaving}
            />
            {!isFreePlan && (
              <div className="flex gap-4 flex-row items-center">
                <LabelWithCheckbox
                  labelText="学習データベースとして登録"
                  name="isRegisterToLearningDatabase"
                  checked={isRegisterToLearningDatabase}
                  onChange={onChangeRegisterToLearningDatabase}
                  value="isRegisterToLearningDatabase"
                  labelClassName="text-white"
                  size="lg"
                  labelEnd
                />
                <DropdownField
                  disabled={!isRegisterToLearningDatabase}
                  value={dropdownValueLearningDatabase}
                  options={dropdownSelectableValueLearningDatabase}
                  onChange={onChangeDropdownLearningDatabase}
                  required
                  name="learningDatabase"
                  menuPlacement="top"
                />
              </div>
            )}
          </div>
        </div>
      )}
      {document && isEditor && (
        <RightFloatSidebar
          document={document}
          headingTitles={[]}
          setRequestHeadingTitles={function (_headingTitles: string[]): void {
            toast.info("Function not implemented.");
          }}
          review={review}
          suggestions={suggestions}
          corrections={corrections}
          rules={rules}
          requestEvaluate={handleRequestEvaluate}
          isEvaluating={isEvaluating}
          onReflectCorrection={onReflectCorrection}
          onCancelCorrection={onCancelCorrection}
          resetEvaluation={resetEvaluation}
        />
      )}
    </div>
  );
};
