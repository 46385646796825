import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

import { useRouter } from "next/router";

import { Button } from "@spesill/components/atoms";
import { IconButton } from "@spesill/components/molecules";
import { ChatListItem } from "@spesill/components/organisms";

import {
  useBoolean,
  useChatRooms,
  useCreateChatRoom,
  useCurrentUser,
  useFetchTenant,
} from "@spesill/hooks";

export const ChatList = () => {
  const { push, query } = useRouter();
  const chatId = query.id as string;
  const {
    isChecked: isOpen,
    setTrue: onOpen,
    setFalse: onClose,
  } = useBoolean(false);
  const { currentUser } = useCurrentUser();
  const { tenant } = useFetchTenant();
  const { createChatRoom, isRequesting } = useCreateChatRoom();
  const { chatRooms, fetchChatRooms } = useChatRooms();
  const {
    isChecked: isListHidden,
    setTrue: onHideList,
    setFalse: onShowList,
  } = useBoolean(false);
  const onClickCreateChatRoom = async () => {
    try {
      await createChatRoom({
        onSuccess: (id) => {
          push(`/chat/${id}`);
        },
      });
      toast.success("チャットを作成しました");
    } catch (e) {
      toast.error(
        e instanceof Error ? e.message : "チャットの作成に失敗しました",
      );
    }
    await axios.post("/api/ops_notification", {
      title: "チャットルームを作成しました",
      options: {
        tenant: tenant?.name,
        user: currentUser?.fullName,
      },
      type: "success",
    });
  };

  const HiddenListMenu = (
    <div className="w-10 p-2 h-full gap-2 min-w-10 transition-all duration-300">
      <IconButton
        className="p-2 rounded-full bg-blueGray-50"
        icon={{
          icon: "tbLayoutSidebarLeftExpand",
          size: "1.25rem",
        }}
        disabled={isRequesting}
        onClick={onShowList}
        color={"gray"}
        tooltip={{
          direction: "right",
          text: "メニューを表示",
          className: "text-xs",
        }}
      />
    </div>
  );

  return isListHidden ? (
    <div className="w-0">{HiddenListMenu}</div>
  ) : (
    <div className="w-80 flex flex-col p-4 border-r border-solid border-blueGray-50 h-full max-h-screen gap-4 min-w-80 transition-all duration-300">
      <div className="flex justify-between">
        <Button
          className="text-sm p-4"
          text="新しいチャット"
          color="primary"
          variant="outlined"
          icon={{
            icon: "aiOutlinePlus",
            size: "1.25rem",
            color: "text-primary-400",
          }}
          disabled={isRequesting}
          onClick={onClickCreateChatRoom}
        />
        <IconButton
          className="p-2 rounded-full"
          icon={{
            icon: "tbLayoutSidebarLeftCollapse",
            size: "1.25rem",
          }}
          color="gray"
          disabled={isRequesting}
          onClick={onHideList}
          tooltip={{
            direction: "right",
            text: "メニューを非表示",
            className: "text-xs",
          }}
        />
      </div>

      <ul className="max-h-screen overflow-y-auto">
        {chatRooms.map((chatRoom) => (
          <ChatListItem
            key={chatRoom.id}
            isSelected={chatId === chatRoom.id}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            chatRoom={chatRoom}
            fetchChatRooms={fetchChatRooms}
          />
        ))}
      </ul>
    </div>
  );
};
