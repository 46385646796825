import { useState, useEffect, useCallback } from "react";

import { SubjectType, subjectPrompts } from "./subjectData";

type UseSubjectSelectionParams = {
  isFeatureEnabled: boolean;
  initialPrompt: string;
  onPromptChange: (prompt: string) => void;
};

export const useSubjectSelection = ({
  isFeatureEnabled,
  initialPrompt,
  onPromptChange,
}: UseSubjectSelectionParams) => {
  const [selectedSubject, setSelectedSubject] = useState<SubjectType | "">("");
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    if (!isFeatureEnabled) {
      setIsEnabled(false);
      setSelectedSubject("");
    }
  }, [isFeatureEnabled]);

  const handleSubjectChange = useCallback((value: string) => {
    setSelectedSubject(value as SubjectType);
  }, []);

  const toggleSubjectMode = useCallback(() => {
    const newState = !isEnabled;
    setIsEnabled(newState);

    if (newState && selectedSubject) {
      onPromptChange(subjectPrompts[selectedSubject]);
    } else if (!newState) {
      onPromptChange(initialPrompt);
      setSelectedSubject("");
    }
  }, [isEnabled, selectedSubject, initialPrompt, onPromptChange]);

  const getFinalPrompt = useCallback(
    (customPrompt: string) => {
      if (isEnabled && selectedSubject) {
        return subjectPrompts[selectedSubject] + "\n\n" + customPrompt;
      }
      return customPrompt;
    },
    [isEnabled, selectedSubject],
  );

  return {
    selectedSubject,
    isEnabled,
    handleSubjectChange,
    toggleSubjectMode,
    getFinalPrompt,
  };
};
