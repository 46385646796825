// src/hooks/useEvaluateRequestBase.ts
import { useState } from "react";
import { toast } from "react-toastify";

import { apiClient } from "@spesill/libs/apiClient";
import { CorrectionRule } from "@spesill/models/api/evaluate_spec";

import { useIncrementAiUsage } from "../firestore/tenantAiUsages/useIncrementAiUsage";
import { useTenantAiCallRestriction } from "../firestore/tenants/useTenantAiCallRestriction";
import { useArray } from "../useArray";
import { useBoolean } from "../useBoolean";

/**
 * 各種タイプ共通の型定義
 */
export type ExcelLocationType = {
  type: "excel";
  row: number;
  col: number;
  cellText?: string;
  sheet?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any; // ライセンス認証により型が取り込めないため any としている
};

export type PdfLocationType = {
  type: "pdf";
  pageIndex: number;
  position: {
    boundingRect: {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      width: number;
      height: number;
    };
    rects: {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      width: number;
      height: number;
    }[];
  };
  isVerticalItem?: boolean;
};

export type LocationType = ExcelLocationType | PdfLocationType;

export type CorrectionType = {
  suggestionMarkdown: string;
  originText: string;
  replacingText: string;
  rule: CorrectionRule;
  reference: string;
  comment?: string;
  location?: LocationType;
  index: number;
};

export type DocType = "word" | "excel" | "pdf";

/**
 * 共通処理：APIリクエスト、状態管理、利用制限チェックなど
 */
export const useEvaluateRequestBase = (
  tenantId: string,
  documentPath: string,
) => {
  const [review, setReview] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const {
    items: corrections,
    setItems: setCorrections,
    findAndRemove: removeCorrection,
  } = useArray<CorrectionType>();
  const [rules, setRules] = useState<CorrectionRule[]>([]);
  const {
    isChecked: isEvaluating,
    setTrue: setEvaluateTrue,
    setFalse: setEvaluateFalse,
  } = useBoolean(false);
  const { incrementAiCallCount } = useIncrementAiUsage();
  const { checkCanCallAi } = useTenantAiCallRestriction();

  const requestEvaluate = async (
    databaseId: string,
    proofreaderPrompt?: string,
    documentKind?: string,
  ) => {
    setEvaluateTrue();
    try {
      const canCall = await checkCanCallAi();
      if (!canCall) {
        toast.error(
          "AIの月間利用回数上限に達しています。プランをアップグレードしてください。",
        );
        setEvaluateFalse();
        return;
      }
      const res = await apiClient().evaluate_spec.$post({
        body: {
          tenant_id: tenantId,
          group_id: databaseId,
          spec_path: documentPath,
          proofreader_prompt: proofreaderPrompt,
          kind: documentKind,
        },
      });

      incrementAiCallCount("evaluateSpec");
      setReview(res.review);
      setSuggestions(res.suggestions);
      setRules(res.rules);
      return res;
    } catch (error) {
      toast.error(
        "AI文書チェックのリクエストに失敗しました。しばらく時間をおいてから再度お試しください。",
      );
      console.error(error);
    } finally {
      setEvaluateFalse();
    }
  };

  const resetEvaluation = () => {
    setReview("");
    setSuggestions([]);
    setCorrections([]);
    setRules([]);
  };

  return {
    review,
    suggestions,
    corrections,
    rules,
    setCorrections,
    resetEvaluation,
    requestEvaluate,
    removeCorrection,
    isEvaluating,
  };
};
