import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

export const Accordion: FC<Props> = ({ children, className = "" }) => {
  return (
    <div className={`flex flex-col gap-y-2 w-full ${className}`}>
      {children}
    </div>
  );
};
